export default [
  {
    path: '/questions',
    name: 'questions',
    component: () => import('@/views/questions/QuestionsList.vue'),
  },
  {
    path: '/questions/edit/:id',
    name: 'question-edit',
    component: () => import('@/views/questions/QuestionEdit.vue'),
    meta: {
      navActiveLink: 'questions',
    },
  },
  {
    path: '/questions/suggest/frames/:movie_id',
    name: 'question-frames-suggest',
    component: () => import('@/views/questions/Suggest/GetMovieFrames.vue'),
    meta: {
      navActiveLink: 'questions',
    },
  },
  {
    path: '/questions/suggest/actors/:movie_id',
    name: 'question-actors-suggest',
    component: () => import('@/views/questions/Suggest/GetMovieActors.vue'),
    meta: {
      navActiveLink: 'questions',
    },
  },
  {
    path: '/questions/suggest/characters/:movie_id',
    name: 'question-characters-suggest',
    component: () => import('@/views/questions/Suggest/GetMovieCharacters.vue'),
    meta: {
      navActiveLink: 'questions',
    },
  },
  {
    path: '/questions/suggest/character-name/:movie_id',
    name: 'question-character-name-suggest',
    component: () => import('@/views/questions/Suggest/GetCharacterName.vue'),
    meta: {
      navActiveLink: 'questions',
    },
  },
  {
    path: '/questions/suggest/description/:movie_id',
    name: 'question-description-suggest',
    component: () => import('@/views/questions/Suggest/GetMovieDescription.vue'),
    meta: {
      navActiveLink: 'questions',
    },
  },
]
