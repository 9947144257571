export default [
  {
    path: '/games',
    name: 'games',
    component: () => import('@/views/game/GamesList.vue'),
  },
  {
    path: '/games/edit/:id',
    name: 'game-edit',
    component: () => import('@/views/game/GameEdit.vue'),
    meta: {
      navActiveLink: 'games',
    },
  },
]
